import { Routes, Route } from 'react-router-dom';
import Whoops404 from './components/4_Whoops404/Whoops404';
import AllProject from './components/AllProject/AllProject';
import About from './components/pagesMenuLinks/About/About';
import Price from './components/pagesMenuLinks/Price/Price';
import Contact from './components/pagesMenuLinks/Contact/Contact';
import Article1 from './components/pagesMenuLinks/Articles/1_Article.jsx';
import Article2 from './components/pagesMenuLinks/Articles/2_Article.jsx';
import Article3 from './components/pagesMenuLinks/Articles/3_Article.jsx';
// uslugi
import Usluga1 from './components/pagesMenuLinks/Uslugi/Usluga1.jsx';
import Usluga2 from './components/pagesMenuLinks/Uslugi/Usluga2.jsx';
import Usluga3 from './components/pagesMenuLinks/Uslugi/Usluga3.jsx';
import Usluga4 from './components/pagesMenuLinks/Uslugi/Usluga4.jsx';
import Usluga5 from './components/pagesMenuLinks/Uslugi/Usluga5.jsx';
import Usluga6 from './components/pagesMenuLinks/Uslugi/Usluga6.jsx';
import Usluga7 from './components/pagesMenuLinks/Uslugi/Usluga7.jsx';
import Usluga8 from './components/pagesMenuLinks/Uslugi/Usluga8.jsx';
import Usluga9 from './components/pagesMenuLinks/Uslugi/Usluga9.jsx';
// en lang
import AllProjectEn from './LangEn/AllProject/AllProject';
import AboutEn from './LangEn/pagesMenuLinks/About/About';
import PriceEn from './LangEn/pagesMenuLinks/Price/Price';
import ContactEn from './LangEn/pagesMenuLinks/Contact/Contact';
import Article1En from './LangEn/pagesMenuLinks/Articles/1_Article';
import Article2En from './LangEn/pagesMenuLinks/Articles/2_Article';
import Article3En from './LangEn/pagesMenuLinks/Articles/3_Article';
import EnUsluga1 from './LangEn/pagesMenuLinks/Uslugi/Usluga1.jsx';
import EnUsluga2 from './LangEn/pagesMenuLinks/Uslugi/Usluga2.jsx';
import EnUsluga3 from './LangEn/pagesMenuLinks/Uslugi/Usluga3.jsx';
import EnUsluga4 from './LangEn/pagesMenuLinks/Uslugi/Usluga4.jsx';
import EnUsluga5 from './LangEn/pagesMenuLinks/Uslugi/Usluga5.jsx';
import EnUsluga6 from './LangEn/pagesMenuLinks/Uslugi/Usluga6.jsx';
import EnUsluga7 from './LangEn/pagesMenuLinks/Uslugi/Usluga7.jsx';
import EnUsluga8 from './LangEn/pagesMenuLinks/Uslugi/Usluga8.jsx';

import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AllProject />} />
        <Route path="/about" element={<About />} />
        <Route path="/price" element={<Price />} />
        <Route path="/usluga1" element={<Usluga1 />} />
        <Route path="/usluga2" element={<Usluga2 />} />
        <Route path="/usluga3" element={<Usluga3 />} />
        <Route path="/usluga4" element={<Usluga4 />} />
        <Route path="/usluga5" element={<Usluga5 />} />
        <Route path="/usluga6" element={<Usluga6 />} />
        <Route path="/usluga7" element={<Usluga7 />} />
        <Route path="/usluga8" element={<Usluga8 />} />
        <Route path="/usluga9" element={<Usluga9 />} />
        <Route path="/article1" element={<Article2 />} />
        <Route path="/article2" element={<Article3 />} />
        <Route path="/article3" element={<Article1 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/en/main" element={<AllProjectEn />} />
        <Route path="/en/about" element={<AboutEn />} />
        <Route path="/en/price" element={<PriceEn />} />
        <Route path="/en/contact" element={<ContactEn />} />
        <Route path="/en/usluga1" element={<EnUsluga1 />} />
        <Route path="/en/usluga2" element={<EnUsluga2 />} />
        <Route path="/en/usluga3" element={<EnUsluga3 />} />
        <Route path="/en/usluga4" element={<EnUsluga4 />} />
        <Route path="/en/usluga5" element={<EnUsluga5 />} />
        <Route path="/en/usluga6" element={<EnUsluga6 />} />
        <Route path="/en/usluga7" element={<EnUsluga7 />} />
        <Route path="/en/usluga8" element={<EnUsluga8 />} />
        <Route path="/en/article1" element={<Article2En />} />
        <Route path="/en/article2" element={<Article3En />} />
        <Route path="/en/article3" element={<Article1En />} />
        <Route path="*" element={<Whoops404 />} />
      </Routes>
    </>
  );
}

export default App;
