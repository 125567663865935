import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/Services/clean_floor.jpg';
import foto1 from '../../../img/uslugi/usluga9.jpg';
import foto2 from '../../../img/uslugi/usluga9.2.jpg';
import foto3 from '../../../img/uslugi/usluga9.3.jpg';
import foto4 from '../../../img/uslugi/usluga9.4.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Profesjonalne sprzątanie
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          <strong>
            Szukasz rzetelnej firmy sprzątającej w Legnicy? Świetnie trafiłeś!
          </strong>
        </p>
        <p>
          Oferujemy kompleksowe usługi utrzymania czystości dla klientów
          indywidualnych, firm i placówek publicznych.
        </p>
        <p>
          W naszej pracy dopasowujemy się do Twoich potrzeb i oczekiwań.
          Pracujemy szybko, dokładnie i przy użyciu skutecznych środków
          czystości, dzięki czemu możemy zagwarantować najwyższą jakość usług.
        </p>
        <h3 className="usluga9-h3">Zakres naszych usług:</h3>
        <div>
          <img src={foto1} className="usluga9-img" alt="foto1" />
          <p>
            Idealne rozwiązanie dla osób, które chcą cieszyć się czystością na
            co dzień, bez konieczności poświęcania czasu na sprzątanie.
            Regularnie odkurzamy, myjemy podłogi, ścieramy kurze i dbamy o
            porządek w Twoim wnętrzu.
          </p>
        </div>
        <div>
          <img src={foto2} className="usluga9-img" alt="foto2" />
          <p>
            Dokładne sprzątanie każdego zakamarka – mycie okien, czyszczenie
            fug, usuwanie trudnych zabrudzeń i odświeżenie całego wnętrza.
            Polecane szczególnie przed ważnymi wydarzeniami, zmianą najemców lub
            po dłuższym czasie bez gruntownego sprzątania.
          </p>
        </div>
        <div>
          <img src={foto3} className="usluga9-img" alt="foto3" />
          <p>
            Pozbywamy się kurzu, pyłu, resztek materiałów budowlanych i
            wszelkich zabrudzeń, które pozostają po pracach remontowych. Dzięki
            nam Twoje wnętrze będzie lśniło czystością i będzie gotowe do
            użytku.
          </p>
        </div>
        <div>
          <img src={foto4} className="usluga9-img" alt="foto4" />
          <p>
            Masz ważne wydarzenie i chcesz, żeby Twój dom lub biuro lśniło? A
            może po prostu nie masz dziś ohoty na sprzątanie? Zostaw to nam!
            Dopasujemy się do Twoich potrzeb i zadbamy o to, co wymaga
            szczególnej czystości.
          </p>
        </div>
        <h3 className="usluga9-h3">Co zyskujesz, wybierając nas?</h3>
        <div className="usluga9-list">
          <p>
            ✅ Dom lub biuro gotowe do życia – przywracamy świeżość i porządek w
            każdej przestrzeni.
          </p>
          <p>✅ Brak wysiłku z Twojej strony – oszczędzasz czas i energię.</p>
          <p>
            ✅ Bezpieczne środki czystości – skuteczność bez szkodliwej chemii.
          </p>
          <p>✅ Profesjonalny sprzęt – radzimy sobie z każdym zabrudzeniem.</p>
        </div>

        <p>
          {' '}
          Nie trać weekendów na sprzątanie – zadzwoń do nas, a my zajmiemy się
          resztą!
        </p>
        <p className="uslugi-notes">
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
